
import RadioButtonCards, { RadioCardOption } from '@/components/FormComponents/RadioButtonCards.vue';
import { MapStateToComputed } from '@/helpers/types';
import { chatMixin } from '@/mixins/chat';
import { analyticsIdentify } from '@/services/analytics';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import mixins from 'vue-typed-mixins';
import { mapState } from 'vuex';
import OnboardingLayout from '../../../../layouts/OnboardingLayout.vue';

type CompleteRegistrationTypes = 'hasNoCompany' | 'hasCompany' | 'vakantiewoningenVerhuur';

export default mixins(chatMixin).extend({
    name: 'CompleteUserRegistration',
    mixins: [chatMixin],
    components: {
        OnboardingLayout,
        RadioButtonCards
    },
    data() {
        return {
            activeCardKey: 'hasCompany' as CompleteRegistrationTypes,
            radioButtonCardOptions: [
                {
                    key: 'hasCompany',
                    i18nPath: 'completeUserRegistration.iHaveACompany',
                    dataAction: 'select-company-already-started'
                },
                {
                    key: 'hasNoCompany',
                    i18nPath: 'completeUserRegistration.iDontHaveACompany',
                    dataAction: 'select-company-not-yet-started'
                },
                {
                    key: 'vakantiewoningenVerhuur',
                    i18nPath: 'completeUserRegistration.verhuur_vakantiewoningen',
                    dataAction: 'select-verhuur-vakantiewoningen'
                }
            ] as RadioCardOption[]
        };
    },
    computed: {
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedUseraccountAuthState>)
    },
    created() {
        if (this.doesUserAlreadyCompletedUserRegistration()) {
            this.$router.push({ name: 'dashboard' });
            return;
        }

        analyticsIdentify(this.currentUserData.id, {
            Verified_Email: true
        });
    },
    methods: {
        doesUserAlreadyCompletedUserRegistration(): boolean {
            return (
                this.currentUserData.userinformation.roles && this.currentUserData.userinformation.roles.length !== 0
            );
        },
        setActiveCardId(selectedCardKey: CompleteRegistrationTypes): void {
            this.activeCardKey = selectedCardKey;
        },
        selectCompanyAlreadyStarted(): void {
            this.$router.replace({
                name: 'CompleteUserRegistrationWithCompany'
            });
        },
        selectCompanyNotYetStarted(): void {
            this.$router.replace({
                name: 'CompleteUserRegistrationWithoutCompany'
            });
        },
        selectVerhuurVakantiewoningen(): void {
            this.$router.replace({
                name: 'CompleteUserRegistrationVakantiewoningen'
            });
        },
        goToNextStep(): void {
            if (this.activeCardKey === 'hasCompany') {
                this.selectCompanyAlreadyStarted();
            } else if (this.activeCardKey === 'hasNoCompany') {
                this.selectCompanyNotYetStarted();
            } else if (this.activeCardKey === 'vakantiewoningenVerhuur') {
                this.selectVerhuurVakantiewoningen();
            } else {
                throw new Error('Type not found');
            }
        }
    }
});
